@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@fontsource/montserrat";
@import "@fontsource/montserrat";

@font-face {
  font-family: "MontserratBlack";
  src: url("../assets/fonts/Montserrat-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "MontserratMedium";
  src: url("../assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "MontserratRegular";
  src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "MontserratSemiBold";
  src: url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "MontserratThin";
  src: url("../assets/fonts/Montserrat-Thin.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "MontserratLight";
  src: url("../assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  max-width: 1900px;
  overflow-x: hidden;
  align-self: center;
  margin-left: auto;
  margin-right: auto;

  font-family: "Montserrat", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */

.swiper-button-next,
.swiper-button-prev {
  visibility: hidden;
}

@media only screen and (min-width: 600px) {
  .swiper-button-next,
  .swiper-button-prev {
    visibility: visible;
  }
}

.opacity-35 {
  opacity: 0.65;
}

.new-solutions-section {
  display: flex;
  height: 800px;
  overflow: hidden; /* Hide scrollbar */
  /* position: relative; */
}

.image-container {
  width: 66.66%; /* Two-thirds of the screen for the image */
  height: 100%;
  position: relative;
}

.text-container {
  width: 33.33%; /* One-third of the screen for the text */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; /* Start at the bottom */
  transition: transform 0.1s ease-out; /* Smooth animation */
}

.text-content {
  padding: 20px;
  text-align: center;
}

.text-content + .text-content {
  margin-top: 840px; /* Adjust based on your design */
}
